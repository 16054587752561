.AnswerDescriptionTitle {
  text-align:  center;
  margin-bottom: 3px;
  color: #098032;
  text-transform: uppercase;
}

.TriviaBox {
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
  background-color: #daecc9;
  margin-bottom: 12px;
  padding-left: 16px;
  padding-right: 16px;
}

.AnswerDescription {
  text-align:  center;
  line-height: 1.3;
  margin-top: 0px !important;
  margin-bottom: 20px !important;
}

.AppContainer {
  max-width: 500px;
  margin: auto;
  @media (max-width:400px) {
    max-width: "100%";
  }
}

.StartTopContainer {
  display: flex;
  height: 50px;
  flex: row;
}

.StartTopLeft {
}

.StartTopRight {
  flex: 1;
  text-align: right;
}

.Scorecard {
  background-color: #F0F0E4;
  margin: 0px auto 20px auto;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.35);
}

.Game {
  background-color: #F0F0E4;
  margin: 0px auto 15px auto;
  padding-bottom: 0px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.35);
}

.CardTop {
  background-color: #143A1F;
  height: 60px;
  display: flex;
  flex-direction: row;
}

.Keyboard {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 30px 16px 10px 16px;
}

.KeysRow {
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 80px;
  margin-bottom: 3px;
  justify-content: center;
}

.LetterKey {
  display: flex;
  width: 25px;
  padding: 1em .5em 1em .5em;
  background: #efefef;
  border: 1px solid #333;
  border-radius: 10px;
  margin: 2px;
  align-items: center;
  justify-content: center;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 900;
}

.Guessed {
  background: #999;
}

.GuessedRight {
  background: #098032;
  color: white;
}

.GuessKey {
  display: flex;
  padding: .5em;
  background: #efefef;
  border: 1px solid #333;
  border-radius: 10px;
  margin: 3px;
  align-items: center;
  justify-content: center;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 900;
}

.Greyed {
  background: #ccc;
}

.DeleteKey {
  display: flex;
  padding: .5em;
  background: #efefef;
  border: 1px solid #333;
  border-radius: 10px;
  margin: 3px;
  align-items: center;
  justify-content: center;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 900;
}



/* SCORECARD STYLES */

.ScorecardBodyArea {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin: 20px 16px 30px 16px;
}

.ScorecardLeftCol {
  display: flex;
  flex-direction: column;
  width: 160px;
  padding-right: 15px;
}

.ScorecardRightCol {
  display: flex;
  flex: 2;
  padding-left: 15px;
  border-left: 2px solid #aaa;
  flex-direction: column;
}

.HoleRow {
  display: flex;
  flex-direction: row;
}

.HoleNumberContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding:  0px;
  width: 30px;
}

.HoleScoreContainer {
  flex:3;
  padding: 0px;
  background: #efefef;
  border: 2px solid #aaa;
  margin: 2px;
  align-items: center;
  justify-content: center;
  width: 100px;
}

.HoleScoreText {
  display: flex;
  font-family: Lato, sans-serif;
  font-size: 28px;
  font-weight: 900;
  justify-content: center;
  margin-top: 0px;
}

.HoleNumberText {
  display: flex;
  margin-right: 10px;
  font-size: 22px;
  font-family: PT Sans Narrow, sans-serif;
  font-weight: 700;
  color: #707070;
}

.ToParTotalContainer {
  display: flex;
  flex: 1;
  background-color: #333;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 20px;
  margin-bottom: 40px;
}

.ToParTotalHead {
  display: flex;
  flex: 1;
  align-items: flex-end;
  color: #999;
  font-size: 17px;
  line-height: 20px;
  font-weight: 700;
  font-family: PT Sans Narrow, sans-serif;
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
}

.ToParTotalScore {
  display: flex;
  flex: 2;
  align-items: flex-start;
  color: #fff;
  color: #ffffff;
  font-size: 100px;
  font-weight: 700;
  font-family: PT Sans Narrow, sans-serif;
}

.ShareButtonContainer {
  align-self: center;
}

.ShareNowButton {
  background-color: #f47728;
  border: none;
  color: #fff;
  padding: 0px 10px 0px 10px;
  margin: 20px 0px 0px 0px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  text-transform: uppercase;
  font-family: Lato, sans-serif;
  font-weight: 900;
  width: 160px;
  height: 60px;
  border-radius: 8px;
}

.CloseButton {
  background-color: #f47728;
  border: none;
  color: #fff;
  padding: 10px 10px 10px 10px;
  margin: 0px 0px 0px 0px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  font-family: Lato, sans-serif;
  font-weight: 900;
  border-radius: 8px;
}

.ParRed {
  color:  #f00;
}

.ParBlack {
  color:  #000;
}

.ParGreen {
  color:  #3e7e1d;
}



/* GAME STYLES */

.ResultBox {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin: 20px 16px 30px 16px;
}

.NextHoleButton {
  background-color: #f47728;
  border: none;
  color: #fff;
  padding: 0px 10px 0px 10px;
  margin: 0px 0px 0px 0px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  text-transform: uppercase;
  font-family: Lato, sans-serif;
  font-weight: 900;
  align-self: right;
  width: 200px;
  height: 60px;
  border-radius: 8px;
}

.ViewScorecardEndButton {
  background-color: #f47728;
  border: none;
  color: #fff;
  padding: 0px 10px 0px 10px;
  margin: 0px 0px 0px 0px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  text-transform: uppercase;
  font-family: Lato, sans-serif;
  font-weight: 900;
  align-self: right;
  width: 200px;
  height: 60px;
  border-radius: 8px;
}


.GuessAreaContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 20px 16px 30px 16px;
}

.GuessRow {
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 80px;
}

.TableHeadRow {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.GuessBox {
  display: flex;
  flex: 1;
  padding: .35em .15em .35em .15em;
  background: #efefef;
  border: 2px solid #aaa;
  flex-grow: 1;
  margin: 2px;
  align-items: center;
  justify-content: center;
}

.GuessText {
  font-family: Lato, sans-serif;
  font-size: 36px;
  font-weight: 900;
}

.GuessIndex {
  display: flex;
  align-items: center;
  justify-content: center;
  padding:  0px;
  width: 30px;
}

.GuessIndexHead {
  width: 80px;
}

.guessIndexText {
  text-align: left;
  margin-right: 10px;
  font-size: 22px;
  font-family: PT Sans Narrow, sans-serif;
  font-weight: 700;
  color: #707070;
}

.guessIndexTextHead {
  text-align: left;
  margin-right: 12px;
  line-height: 10px;
  font-size: 10px;
  font-family: PT Sans Narrow, sans-serif;
  font-weight: 900;
  color: #000000;
}

.ResultCorrectPlace {
  background-image: url(./correct-rightplace.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #098032;
  border-color: #098032;
}

.ResultCorrectWrongPlace {
  background-image: url(./correct-wrongplace.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fd8d0b;
  border-color: #fd8d0b;
}

.ResultWrong {
  background-color: #FFFFFF;
}

.LeftTopBox {
  display: flex;
  flex: 1;
}

.LeftTopBox {
  display: flex;
  flex:  1;
  color: #707070;
  font-size: 22px;
  margin-left: 15px;
  background-image: url(./toplogo.png);
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
}

.CenterTopBoxScorecard h1 {
  display: flex;
  flex:  1;
  color: #ccc;
  font-size: 18px;
  margin: 18px 20px 0px 38px;
  text-transform: uppercase;
  font-family: PT Sans Narrow, sans-serif;
  font-weight: 700;
}

.CenterTopBoxGame h1 {
  display: flex;
  flex:  1;
  color: #ccc;
  font-size: 18px;
  margin: 10px 10px 0px 48px;
  text-transform: uppercase;
  font-family: PT Sans Narrow, sans-serif;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
}

.RightTopBox {
  display: flex;
  flex: 1;
  margin: 10px 18px 10px 20px;
  justify-content: right;
}

.FlipButton {
  background-color: #f47728;
  border: none;
  color: #fff;
  padding: 0px 10px 0px 10px;
  margin: 0px 0px 0px 0px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 11px;
  text-transform: uppercase;
  font-family: Lato, sans-serif;
  font-weight: 900;
  align-self: right;
  border-radius: 8px;
}

.AdBox {
  /*
  text-align: center;
  height: 40px;
  background-color: #ccc;
  */
  height: 90px;
}

.AdText {
  display: inline-block;
  vertical-align: center;
  color: #ddd;
}


.legalcopy {
  font-size: 8px !important;
  line-height: 14px !important;
}







